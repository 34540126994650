<template>
  <div>
    <v-card flat>
      <v-card-title
        class="mb-2 text-h4"
        style="word-break: keep-all"
      >
        Lieux
      </v-card-title>
      <v-card-subtitle>
        Cette page permet aux administrateurs de gérer les lieux disponibles
        pour les FOPs.
      </v-card-subtitle>

      <v-card-text v-if="!loading">
        <v-row
          align="center"
          justify="end"
          class="pb-3"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="search"
              outlined
              rounded
              hide-details="auto"
              dense
              label="Recherche"
              clearable
            />
          </v-col>
        </v-row>

        <v-virtual-scroll
          :items="getLocations"
          item-height="60"
          height="600"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-icon>
                <TooltipBtn
                  icon="mdi-delete"
                  icononly
                  tooltip="Supprimer"
                  color="red"
                  dark
                  :click="() => deleteLocation(item)"
                />
              </v-list-item-icon>
              <v-list-item-title>
                <v-text-field
                  v-model="item.name"
                  type="text"
                  outlined
                  dense
                  hide-details="auto"
                  @change="editLocation(item)"
                />
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-virtual-scroll>
        <v-list-item>
          <v-list-item-icon>
            <TooltipBtn
              icon="mdi-plus"
              icononly
              tooltip="Ajouter"
              color="green"
              outlined
              :disabled="!new_name"
              :click="() => createLocation()"
            />
          </v-list-item-icon>
          <v-list-item-title>
            <v-text-field
              v-model="new_name"
              type="text"
              outlined
              dense
              hide-details="auto"
              @keydown.enter="createLocation()"
            />
          </v-list-item-title>
        </v-list-item>
      </v-card-text>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>
  </div>
</template>

<script>
import TooltipBtn from "@/components/Inputs/TooltipBtn";

export default {
  components: { TooltipBtn },
  data() {
    return {
      locations: [],
      loading: true,
      search: "",
      new_name: "",
    };
  },
  computed: {
    getLocations() {
      if (!this.search) return this.locations;
      let reg = new RegExp(this.search, "i");
      return this.locations.filter((x) => x.name.match(reg));
    },
  },
  mounted() {
    // Get groups
    this.axiosPost(process.env.VUE_APP_API_URL + "/admin/getAllLocations", {})
      .then((res) => {
        this.loading = false;
        this.locations = res.data.sort();
      })
      .catch((err) => {
        this.$alert.$emit("snackbar", {
          message: err.response.data,
          status: "error",
        });
      });
  },
  methods: {
    deleteLocation(location) {
      this.$alert.$emit("confirm", {
        title: "Supprimer " + location.name + " ?",
        message: "Supprimer la location? ! Cette action est irréversible !",
        callback: () => {
          this.axiosPost(
            process.env.VUE_APP_API_URL + "/admin/deleteLocation",
            {
              location,
            }
          ).then((res) => {
            if (res.status == 200)
              this.locations.splice(
                this.locations.findIndex((x) => x.name == location.name),
                1
              );
          });
        },
      });
    },
    editLocation(location) {
      this.axiosPost(process.env.VUE_APP_API_URL + "/admin/updateLocation", {
        location,
      });
    },
    createLocation() {
      this.axiosPost(process.env.VUE_APP_API_URL + "/admin/createLocation", {
        name: this.new_name,
      }).then((res) => {
        if (res.status == 200) this.locations.push(res.data);
      });
      this.new_name = "";
    },
  },
};
</script>
