<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        :class="classes"
        :outlined="outlined"
        v-bind="attrs"
        :value="value"
        :icon="icononly"
        :to="to"
        :loading="loading"
        :small="small"
        :x-small="xSmall"
        :color="btnColor"
        @click.stop.prevent="click()"
        v-on="(Boolean(tooltip) && on) || undefined"
      >
        <v-icon
          :x-large="xLarge"
          :color="color"
        >
          {{ icon }}
        </v-icon>
        {{ text }}
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: String,
    icon: String,
    icononly: Boolean,
    text: String,
    click: {
      type: Function,
      default: () => null,
    },
    loading: Boolean,
    disabled: Boolean,
    classes: String,
    color: String,
    btnColor: String,
    outlined: Boolean,
    tooltip: String,
    to: String,
    xLarge: Boolean,
    small: Boolean,
    xSmall: Boolean,
  },
};
</script>